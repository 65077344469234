body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*background: black;*/
  min-height: 100vh;
  min-width: 100vw;
  position: relative;
  background: #e6d844;
}

#bg {
  background: #e6d844;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#bg img {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: chocolate !important;
}

a {
  color: chocolate;
}

.btn-continue {
  background: chocolate;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-left: 20%;
  margin-bottom: 2em;
  margin-top: 2em;
}

.btn-pass {
  background: chocolate;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-bottom: 2em;
  margin-top: 2em;
  margin-right: .5em;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  /*overflow: hidden;*/
  display: block;
  /*background: black;*/
}

.loading {
  justify-content: center;
  align-items: center;
  padding: 2em;
  position: absolute;
  top: 0;
  left: 0;
}

.App {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  /*overflow: hidden;*/
  position: relative;
  display: block;
  /*background: #e6d844;*/
}

#AR-canvas {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.intro-container {
  width: 100vw;
  position: relative;
  padding: 2em;
}

.ar-button {
  z-index: 999;
  cursor: pointer;
  position: absolute;
  background: chocolate;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  margin-left: 30%;
  margin-bottom: 2em;
  margin-top: 1em;
}

.debug {
  position: absolute;
  top: 1em;
  left: 1em;
  background: white;
  z-index: 999 !important;
}

.halfOpacity {
  opacity: 0.5;
}

.languages {
  position: absolute;
  right: 1em;
  top: 1em;
}

.sel {
  display: flex;
  float: left;
}
.active {
  font-weight: bold;
}

#addMessage,
#overlay {
  position: absolute;
  top: 1em;
  left: 1em;
  z-index: 9999;
}

#canvas {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 80% !important;
  z-index: 5;
}
